// @types
@import "../../../styles/variables";

.btn {
  @include flexbox;
  @include flexbox-justify-center;
  @include flexbox-align-center;
  width: 100%;
  min-height: $btn-height;
  padding: 20px 10px;
  background: $color-btn-primary;
  border: 1px solid $color-btn-primary;
  border-radius: $border-radius;
  line-height: 1.2;
  font-size: $font-size16;
  font-weight: bold;
  text-align: center;
  color: $color-font-white;
  span {
    display: block;
    padding-left: 8px;
    padding-right: 17px;
    background-image: url(/images/arw-right-white.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 6px 10px;
    white-space: pre-line;
  }
  &:hover {
    opacity: $opacity;
  }
  &:disabled {
    background: $color-btn-gray-secondary;
    border: 1px solid $color-btn-gray-secondary;
    color: $color-font-white;
    cursor: default;
  }
  &.small {
    min-height: $btn-height-small;
    padding: 10px 10px;
    font-size: $font-size16;
    font-weight: bold;
  }
}
.primary {
  background: $color-btn-primary;
  border: 1px solid $color-btn-primary;
  color: $color-font-white;
  &:disabled {
    background: $color-btn-gray-secondary;
    border: 1px solid $color-btn-gray-secondary;
    color: $color-font-white;
  }
}
.primaryOutline {
  background: transparent;
  border: 1px solid $color-btn-primary;
  color: $color-btn-primary;
  span {
    background-image: url(/images/arw-right-black.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 6px 10px;
  }
  &:disabled {
    background: transparent;
    border: 1px solid $color-btn-gray-secondary;
    color: $color-btn-gray-secondary;
  }
}
.back {
  background: transparent;
  border: 1px solid $color-btn-primary;
  color: $color-btn-primary;
  span {
    padding-left: 17px;
    padding-right: 8px;
    background-image: url(/images/arw-left-black.svg);
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 6px 10px;
  }
  &:disabled {
    background: transparent;
    border: 1px solid $color-btn-gray-secondary;
    color: $color-btn-gray-secondary;
  }
}
.cancel {
  background: $color-btn-gray-secondary;
  border: 1px solid $color-btn-gray-secondary;
  color: $color-btn-black;
  &:disabled {
    background: $color-btn-gray-secondary;
    border: 1px solid $color-btn-gray-secondary;
    color: $color-font-white;
  }
}
.danger {
  background: $color-btn-danger;
  border: 1px solid $color-btn-danger;
  color: $color-font-white;
  &:disabled {
    background: $color-btn-gray-secondary;
    border: 1px solid $color-btn-gray-secondary;
    color: $color-font-white;
  }
}
.dangerOutline {
  background: transparent;
  border: 1px solid $color-btn-danger;
  color: $color-btn-danger;
  &:disabled {
    background: transparent;
    border: 1px solid $color-btn-gray-secondary;
    color: $color-btn-gray-secondary;
  }
}
.weightNormal {
  font-weight: normal;
}
.noArw {
  span {
    padding-left: 0;
    padding-right: 0;
    background-image: none;
  }
}
.couponDetail {
  min-height: 25px;
  padding: 5px 5px;
  font-size: $font-size12;
  font-weight: normal;
  span {
    padding-left: 0;
    padding-right: 0;
    background-image: none;
  }
}

.inherit {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
}

.deleteBtn {
  width: 22px;
  height: 22px;
  min-height: 22px;
  background: url(/images/icn-close.svg) no-repeat center center;
  background-size: 22px 22px;
  border: 0;
  border-radius: 0;
  line-height: 22px;
  span {
    display: none;
  }
}
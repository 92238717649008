// ---------------------------------------------------
// reset
// ---------------------------------------------------
* {
  margin: 0;
  padding: 0;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  text-align: inherit;
  color: inherit;
  font-weight: inherit;
  box-sizing: border-box;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
li {
  list-style: none;
}
em {
  font-style: normal;
}
input,
textarea,
select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: $set-sans-serif;
}
select {
  &::-ms-value {
    background: none;
    color: inherit;
  }
}
button {
  display: inline;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  overflow: visible;
  color: inherit;
  font-family: $set-sans-serif;
}
input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px white inset;
}
input::-ms-clear {
  visibility: hidden;
}
img {
  border: 0;
  max-width: 100%;
}
/* for firefox */
a::-moz-focus-inner,
input[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0px;
}

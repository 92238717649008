.container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 62px;
  font-weight: bold;
}

.description {
  font-size: 20px;
  padding: 16px;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

/* 色指定 */
$color-black: #000;
$color-white: #fff;
$color-red: #cc0f0f;
$color-blue: #00f;
$color-green: #008000;
$color-orange: #FFA500;

/* 色指定　意味 */
$color-primary: $color-black;
$color-secondary: #997e5d;
$color-destructive-error: #cc0f0f;
$color-gray-bg: #efefef;
$color-gray-hairline: #e0e0e0;
$color-text-black: #333333;
$color-gray-primary: #999999;
$color-gray-secondary: #cccccc;
$color-text-gray: $color-gray-primary;

/* 色指定　使用場所 */
/* 背景 */
$color-main-bg: $color-white;
$color-header-bg: $color-white;
$color-dev-header-bg: $color-green;
$color-stg-header-bg: $color-orange;
$color-footer-bg: $color-text-black;
$color-subnav-bg: $color-white;
$color-container-bg: $color-white;
$color-bg-white: $color-white;
$color-bg-disabled: $color-gray-secondary;
$color-bg-gray: $color-gray-bg;
$color-bg-hairline: $color-gray-hairline;
$color-bg-dialog-filter: rgba(0, 0, 0, 0.9);
$color-bg-danger: $color-destructive-error;
/* テキスト */
$color-font-main: $color-text-black;
$color-font-white: $color-white;
$color-font-gray: $color-text-gray;
$color-font-hairline: $color-gray-hairline;
$color-font-placeholder: $color-gray-primary;
$color-link: $color-primary;
$color-required: $color-destructive-error;
$color-font-red: $color-destructive-error;
/* 線 */
$color-border: $color-gray-secondary;
$color-border-secondary: $color-gray-secondary;
$color-border-hairline: $color-gray-hairline;
$color-border-text-gray: $color-text-gray;
/* ボタン */
$color-btn-primary: $color-primary;
$color-btn-secondary: $color-secondary;
$color-btn-white: $color-white;
$color-btn-black: $color-text-black;
$color-btn-gray-primary: $color-gray-primary;
$color-btn-gray-secondary: $color-gray-secondary;
$color-btn-danger: $color-destructive-error;

/* ベンダープレフィックスのリスト */
$set-prefix: -webkit-, "";

/* フォントファミリー：ゴシック */
$set-sans-serif: 'Noto Sans JP', -apple-system, Segoe UI, Helvetica Neue,
  Hiragino Kaku Gothic ProN, "Hiragino Sans", "メイリオ", "MS PGothic",
  "ＭＳ Ｐゴシック", Osaka, sans-serif;

/* 幅指定 */
/* ウィンドウ左右余白 */
$layout-window-padding: 20px;

/* コンテンツブロック */
/* コンテナー最大幅 */
$layout-container-width: 800px;
/* コンテンツブロックの左右padding */
$contents-padding: 20px;
/* コンテンツブロックの天地padding */
$contents-padding-top: 20px;
/* コンテンツブロックのガター */
$contents-gutter: 8px;
/* コンテナの天地margin */
$container-margin-top: 20px;

/* ヘッダー高さ */
$header-height: 50px;


/* 詳細・登録・確認画面の基本レイアウト */
/* コンテンツ部分の見出し・ラベルの幅 */
$detail-title-width: 170px;
/* 左右padding */
$detail-padding: 30px;
/* コンテンツ部分の見出し・ラベルの右padding */
$detail-title-padding: 20px;

/* inputの補足/追加したタグの表示 */
/* 説明エリアの基準幅 */
$input-note-width: 355px;
/* フォームエリアのパーツ間のマージン */
$input-item-margin: 5px;

/* 角丸の基本 */
$border-radius: 5px;
$input-radius: 5px;

/* シャドウの基本 */
$box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
$box-border-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05),
  0px 4px 4px rgba(0, 0, 0, 0.05);
$box-border-shadow-up: 0px -1px 0px rgba(0, 0, 0, 0.05),
  0px -4px 4px rgba(0, 0, 0, 0.05);
$box-border-shadow-hover: 0px 2px 16px rgba(0, 0, 0, 0.07);

/* opacityの基本 */
$opacity: 0.7;

/* 線の基本 */
$border-gray: 1px solid $color-gray-secondary;
$border-solid: 1px solid $color-border-hairline;
$border-dashed: 1px dashed $color-border-hairline;

/* ボタン・inputの基本の高さ */
$btn-height-small: 50px;
$btn-height: 60px;
$btn-height-large: 48px;
$textarea-height: 145px;

/* font-size */
$font-size26: 26px;
$font-size24: 24px;
$font-size22: 22px;
$font-size21: 21px;
$font-size20: 20px;
$font-size19: 19px;
$font-size18: 18px;
$font-size17: 17px;
$font-size16: 16px;
$font-size15: 15px;
$font-size14: 14px;
$font-size13: 13px;
$font-size12: 12px;
$font-size11: 11px;
$font-size10: 10px;

/* clearfix */
@mixin clearfix {
  zoom: 1;
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

/* 天地中央配置 */
@mixin vertical-middle {
  display: block;
  position: relative;
  top: 50%;
  @each $prefix in $set-prefix {
    #{$prefix}transform: translateY(-50%);
  }
}

/* flex */
/* flex */
@mixin flexbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
/* flex inline */
@mixin inline-flexbox {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
/* flex 左から（デフォルト） */
@mixin flexbox-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
/* flex 右から */
@mixin flexbox-row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
/* flex 縦 */
@mixin flexbox-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
/* flex 折り返しなし（デフォルト） */
@mixin flexbox-nowrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
/* flex 折り返しあり */
@mixin flexbox-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
/* flex 左寄せ（デフォルト） */
@mixin flexbox-justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
/* flex 右寄せ */
@mixin flexbox-justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
/* flex センタリング */
@mixin flexbox-justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
/* flex space-between（左右は端合わせで等間隔） */
@mixin flexbox-justify-space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
/* flex space-around（全て等間隔） */
@mixin flexbox-justify-space-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
/* flex 100%に引き伸ばす（デフォルト） */
@mixin flexbox-align-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
/* flex 上寄せ */
@mixin flexbox-align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
/* flex 下寄せ */
@mixin flexbox-align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
/* flex 天地中央寄せ */
@mixin flexbox-align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
/* flex ベースライン */
@mixin flexbox-align-baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}
/* flex 子要素を伸ばす比率 */
@mixin flexbox-grow($grow: 1) {
  -webkit-box-flex: $grow;
  -ms-flex-positive: $grow;
  flex-grow: $grow;
}
/* flex 子要素を縮める比率　0:縮めない */
@mixin flexbox-shrink($shrink: 0) {
  -ms-flex-negative: $shrink;
  flex-shrink: $shrink;
}

/* 基本のテキスト */
@mixin textStyle {
  font-size: $font-size14;
  line-height: 1.6;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
@mixin textStyleSub {
  font-size: $font-size12;
  line-height: 1.5;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

/* 幅が狭いデバイスのとき */
@mixin media-width-content-sp($width: 360px) {
  @media screen and (max-width: $width) {
    @content;
  }
}

/* 幅がタブレット以上のデバイスのとき */
@mixin media-width-content-tablet($width: 605px) {
  @media screen and (min-width: $width) {
    @content;
  }
}
@import '../../../styles/variables';

.formBtnSet {
  @include flexbox;
  @include flexbox-justify-space-between;
  border-top: $border-solid;
  &.alignRight {
    @include flexbox-justify-end;
  }
  &.alignLeft {
    @include flexbox-justify-start;
  }
  &.alignCenter {
    @include flexbox-justify-center;
  }
  &.noBorder {
    border-top: none;
  }
  & > .item {
    padding-top: 49px;
    &.small {
      width: 162px;
    }
    &.borderTop {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: -1px;
        width: 100%;
        height: 2px;
        background: $color-btn-primary;
      }
    }
    &.borderTopCancel {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: -1px;
        width: 100%;
        height: 2px;
        background: $color-btn-gray-secondary;
      }
    }
    &.borderTopDanger {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: -1px;
        width: 100%;
        height: 2px;
        background: $color-btn-danger;
      }
    }
  }
  &.btnOnlyBlock {
    border-top: none;
    position: relative;
    @include flexbox-justify-center;
    & > .item {
      padding-top: 0;
      width: 264px;
      &.backBtn {
        position: absolute;
        left: 0;
        top: 0;
        width: 128px;
      }
    }
  }
  &.btnOnlyBlock.alignLeft {
    @include flexbox-justify-start;
    & > .item {
      &.backBtn {
        position: relative;
      }
    }
  }
  &.dialogBtnSet {
    @include flexbox;
    @include flexbox-justify-space-between;
    &.alignRight {
      @include flexbox-justify-end;
    }
    &.alignLeft {
      @include flexbox-justify-start;
    }
    &.alignCenter {
      @include flexbox-justify-center;
    }
    & > .item {
      width: calc(50% - 5px);
      max-width: 310px;
      padding-top: 0;
    }
  }
}

:global(.bodyContainerSp) .formBtnSet {
  display: block;
  border-top: none;
  &.alignRight {
    @include flexbox-justify-end;
  }
  &.alignLeft {
    @include flexbox-justify-start;
  }
  &.alignCenter {
    @include flexbox-justify-center;
  }
  &.noBorder {
    border-top: none;
  }
  & > .item {
    width: 100%;
    padding-top: 0;
    &.small {
      width: calc(50% - 5px);
      max-width: 162px;
    }
    &.borderTop {
      &:after {
        content: '';
        display: none;
      }
    }
    &.borderTopCancel {
      &:after {
        content: '';
        display: none;
      }
    }
    &.borderTopDanger {
      &:after {
        content: '';
        display: none;
      }
    }
  }
  & > .item + .item {
    margin-top: 20px;
  }
  &.alignCenter > .item {
    width: 100%;
  }
  &.btnOnlyBlock {
    border-top: none;
    position: relative;
    @include flexbox;
    @include flexbox-justify-end;
    & > .item {
      margin-top: 0;
      width: 50%;
      &.backBtn {
        position: absolute;
        left: 0;
        top: 0;
        width: auto;
      }
    }
  }
  &.btnOnlyBlock.alignLeft {
    @include flexbox-justify-start;
    & > .item {
      &.backBtn {
        position: relative;
      }
    }
  }
  &.dialogBtnSet {
    @include flexbox;
    @include flexbox-justify-space-between;
    &.alignRight {
      @include flexbox-justify-end;
    }
    &.alignLeft {
      @include flexbox-justify-start;
    }
    &.alignCenter {
      @include flexbox-justify-center;
    }
    & > .item {
      width: calc(50% - 5px);
      max-width: none;
      padding-top: 0;
    }
    & > .item + .item {
      margin-top: 0;
    }
    &.alignCenter > .item {
      width: 100%;
    }
  }
}

@import '../../../styles/variables';

.loadingWrap {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 11000;
  @include flexbox;
  @include flexbox-column;
  @include flexbox-justify-center;
  @include flexbox-align-center;
  width: 100%;
  height: 100vh;
  padding: 0 $layout-window-padding;
  background: $color-bg-dialog-filter;
  .loadingImg {
    position: absolute;
    img {
      width: 100px;
      height: 100px;
      animation: spin 2s linear infinite;
    }
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

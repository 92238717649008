@import "./_variables";
@import "./_reset";

/* ---------------------------------------------------
common
--------------------------------------------------- */
html {
  touch-action: manipulation;
  background: $color-main-bg;
  font-size: 13px;
}
body {
  font-family: $set-sans-serif;
  font-size: 13px;
  color: $color-font-main;
  line-height: 1;
  -webkit-text-size-adjust: 100%;
  background: $color-white;
  .bodyContainer {
    padding-top: $header-height;
  }
}
a {
  color: $color-link;
  text-decoration: none;
}
button,
a {
  word-break: break-all;
}
button.textLink,
a.textLink {
  color: $color-font-main;
  display: inline;
  text-decoration: underline;
}
.clearfix {
  @include clearfix;
}
.cl {
  clear: both;
}

.breakword {
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.nowrap {
  white-space: nowrap;
}

.underline {
  font-weight: normal;
  text-decoration: underline;
}
.lineThrough {
  font-weight: normal;
  text-decoration: line-through;
}
b,
strong,
.bold {
  font-weight: bold;
}

.textAlignCenter {
  text-align: center !important;
}
.textAlignLeft {
  text-align: left !important;
}
.textAlignRight {
  text-align: right !important;
}

/* 汎用文字色 */
.colorMain {
  color: $color-font-main;
}
.colorWhite {
  color: $color-font-white;
}
.colorGray {
  color: $color-font-gray;
}
.colorPrimary {
  color: $color-primary;
}
.colorSecondary {
  color: $color-secondary;
}
.colorRed {
  color: $color-font-red;
}
.colorRequired {
  color: $color-required;
}
.colorError {
  color: $color-destructive-error;
}

/* margin/padding */
@for $i from 0 through 20 {
  .mt#{$i * 5} {
    margin-top: #{$i * 5}px !important;
  }
  .mb#{$i * 5} {
    margin-bottom: #{$i * 5}px !important;
  }
  .ml#{$i * 5} {
    margin-left: #{$i * 5}px !important;
  }
  .mr#{$i * 5} {
    margin-right: #{$i * 5}px !important;
  }
  .pt#{$i * 5} {
    padding-top: #{$i * 5}px !important;
  }
  .pb#{$i * 5} {
    padding-bottom: #{$i * 5}px !important;
  }
  .pl#{$i * 5} {
    padding-left: #{$i * 5}px !important;
  }
  .pr#{$i * 5} {
    padding-right: #{$i * 5}px !important;
  }
}

/* main */
#main {
  position: relative;
  z-index: 1;
  .row {
    @include flexbox;
    @for $i from 1 through 12 {
      .col#{$i} {
        width: calc(
          ((100% + #{$contents-gutter}) / 12 * #{$i}) - #{$contents-gutter}
        );
        margin-right: $contents-gutter;
      }
    }
    & > *:last-child {
      margin-right: 0 !important;
    }
  }
}

/* container */
.container {
  max-width: $layout-container-width;
  margin: 0 auto;
  padding: $contents-padding;
}
.container + .container,
.linkContainer + .container {
  margin-top: $container-margin-top;
}
//SP
.containerSp.signUp {
  padding: $contents-padding;
  margin-top: 170px;
  &.marginTop {
    margin-top: $container-margin-top;
  }
  margin-left: 0;
  margin-right: 0;
}
.containerSp + .containerSp,
.containerSp + .linkContainerSp,
.linkContainerSp + .containerSp,
.linkContainerSp + .linkContainerSp {
  margin-top: $container-margin-top;
}

/* ページヘッダー */
.pageHeader {
  @include flexbox;
  @include flexbox-justify-space-between;
  @include flexbox-align-center;
  min-height: 90px;
  padding: $contents-padding;
  .titleWrap {
    .pageTitle {
      display: inline-block;
      margin-right: 10px;
      font-size: $font-size18;
      font-weight: bold;
      line-height: 1.2;
      vertical-align: middle;
    }
    .subText {
      display: inline-block;
      font-size: $font-size13;
      font-weight: normal;
      line-height: 1.2;
      vertical-align: middle;
    }
    .text {
      margin-top: 10px;
      p {
        font-size: $font-size13;
        line-height: 1.2;
        color: $color-font-gray;
      }
    }
    .status {
      margin-bottom: 10px;
    }
  }
}

/* label + input + 補足・エラー */
.inputNoteSet {
  padding-top: 20px;
  padding-bottom: 48px;
  border-bottom: $border-solid;
  &.inputNoteSetBorderNone {
    padding-bottom: 50px;
    border-bottom: 0;
  }
  .inputTitleWrap {
    margin-bottom: 20px;
    .title {
      font-size: $font-size18;
      font-weight: bold;
      line-height: 1.2;
    }
  }
  .inputMainWrap {
    .subTitle {
      margin-top: 5px;
      margin-bottom: 17px;
      font-size: $font-size14;
      font-weight: normal;
      line-height: 1.2;
    }
    .inputItemWrap {
      @include flexbox;
      @include flexbox-justify-space-between;
      @include flexbox-align-center;
      &.inputItemWrapFree {
        @include flexbox-justify-start;
        & > .inputItem {
          margin-right: calc(#{$input-item-margin} * 2);
        }
      }
      &.inputItemWrapZip {
        @include flexbox-justify-start;
        & > .inputItemZip {
          width: calc(50% - #{$input-item-margin});
          max-width: 200px;
        }
        & > .inputItemZip1 {
          max-width: 130px;
        }
        & > .inputItemBridge {
          width: 30px;
          text-align: center;
        }
        & > .inputItemZip2 {
          max-width: 200px;
        }
      }
      &.inputItemWrapMail {
        & > .inputItemMail1 {
          width: calc((100% - 30px) * 0.4);
        }
        & > .inputItemBridge {
          width: 30px;
          text-align: center;
        }
        & > .inputItemMail2 {
          width: calc((100% - 30px) * 0.6);
        }
      }
      &.inputItemWrapBirth {
        & > .inputItemYear {
          width: calc((100% - (#{$input-item-margin} * 4)) * 0.44);
        }
        & > .inputItemMonth {
          width: calc((100% - (#{$input-item-margin} * 4)) * 0.28);
        }
        & > .inputItemDate {
          width: calc((100% - (#{$input-item-margin} * 4)) * 0.28);
        }
      }
      & > .inputItemHalf {
        width: calc(50% - #{$input-item-margin});
      }
      & > .inputItemFull {
        width: 100%;
      }
    }
    .inputNote,
    .inputItemNote {
      @include textStyle;
      .error {
        color: $color-destructive-error;
      }
      p {
        padding-top: 20px;
        @include textStyle;
      }
      .noteList {
        padding-top: 20px;
        li {
          position: relative;
          @include textStyle;
          .mark {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
          }
          .text {
            display: block;
            margin-left: 1em;
          }
        }
      }
    }
  }
  .inputMainWrap + .inputMainWrap {
    margin-top: 30px;
  }
}
/* snackbarのz-index指定 */
.snackbarZIndex {
  z-index: 10500 !important;
}

/* dialog */
.dialogWrap {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
  @include flexbox;
  @include flexbox-column;
  @include flexbox-justify-center;
  @include flexbox-align-center;
  width: 100%;
  height: 100vh;
  padding: 0 10px;
  background: $color-bg-dialog-filter;
  .dialogContainer {
    position: relative;
    width: 100%;
    max-width: 500px;
    max-height: calc(100vh - 200px);
    padding: 80px $contents-padding;
    background: $color-container-bg;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    .dialogTextSet {
      .title {
        font-size: $font-size18;
        font-weight: bold;
        line-height: 1.2;
        text-align: center;
      }
      .text {
        padding: $contents-padding 0;
        @include textStyle;
        p + p {
          margin-top: 16px;
        }
      }
    }
    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 22px;
      height: 22px;
      img {
        display: block;
        width: 22px;
        height: 22px;
      }
    }
    .dialogBtnWrap {
      padding-top: $contents-padding;
      .btnWrap + .btnWrap {
        margin-top: 20px;
      }
    }
  }
}